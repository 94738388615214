<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-sm-10">
        <div class="card card-wizard" id="wizardCard">
          <form-wizard
            shape="tab"
            @on-complete="wizardComplete"
            @on-error="handleErrorMessage"
            error-color="#FB404B"
            color="#35495E"
            title="Create New Customer"
            subtitle="Please enter the full customer/venue information."
            ref="wizard"
            :key="wizardKey"
          >
            <tab-content
              title="General Info"
              class="col-12"
              :before-change="() => validateStep('CustomerGeneralInfo')"
              icon="nc-icon nc-badge"
            >
              <customer-general-info
                ref="CustomerGeneralInfo"
                @on-validated="onStepValidated"
                @on-generalInfoSubmiting="generalInfoData"
                :newCustomer="newCustomer"
              ></customer-general-info>
            </tab-content>

            <tab-content
              v-if="!firstVenue"
              title="Venues"
              class="col-12"
              :before-change="preventNextStep"
              icon="nc-icon nc-notes"
            >
              <customer-venue-table
                ref="CustomerVenueTable"
                :venueData="newCustomers.venue"
                @fireVenueLoop="startVenueLoop"
              ></customer-venue-table>
              <div v-if="errorMsg != null">
                <span class="error">{{ errorMsg }}</span>
              </div>
            </tab-content>

            <tab-content
              v-if="venueLoop"
              title="Venues' Info"
              class="col-12"
              :before-change="() => validateStep('CustomerVenuesInfo')"
              icon="nc-icon nc-notes"
            >
              <customer-venues-info
                ref="CustomerVenuesInfo"
                @on-validated="onStepValidated"
                @on-VenueSubmiting="getVenuePOSData"
                :currentVenue="choosenVenue"
              ></customer-venues-info>
            </tab-content>

            <tab-content
              v-if="venueLoop"
              title="Venue Contacts' Info"
              class="col-12"
              :before-change="() => validateStep('CustomerVenueContacts')"
              icon="nc-icon nc-notes"
            >
              <customer-venue-contacts
                ref="CustomerVenueContacts"
                @on-validated="onStepValidated"
                @on-venueContactSubmitting="getVenueContactsData"
                :currentVenueContacts="choosenVenue.venueContacts"
              ></customer-venue-contacts>
            </tab-content>

            <tab-content
              v-if="venueLoop"
              title="Service Personels' Info"
              class="col-12"
              :before-change="() => validateStep('CustomerServicePersonnel')"
              icon="nc-icon nc-notes"
            >
              <customer-service-personnel
                ref="CustomerServicePersonnel"
                @on-validated="onStepValidated"
                @on-LoopLastStep="venueLoopReset = true"
                @on-personnelSubmitting="getServicePersonnelData"
                :currentServicePersonnel="choosenVenue.servicePerson"
              ></customer-service-personnel>
            </tab-content>

            <tab-content
              title="Finish"
              class="col-12"
              icon="nc-icon nc-check-2"
              :after-change="resetVenueLoop"
            >
              <div>
                <h4 class="text-center text-space">
                  Greate the Customer "{{ newCustomers.customerInfo.name }}" has
                  been created!
                  <br />
                  <small
                    >Click on "<b>Add New Customer</b>" to save your changes and
                    to restart the wizard</small
                  >
                </h4>
              </div>
            </tab-content>

            <template slot="footer" slot-scope="props">
              <div class="wizard-footer-left">
                <wizard-button
                  v-if="
                    (firstVenue ||
                      (!firstVenue && venueLoop && props.activeTabIndex != 2) ||
                      (!firstVenue && !venueLoop)) &&
                      props.activeTabIndex > 0
                  "
                  @click.native="props.prevTab()"
                  :style="props.fillButtonStyle"
                  >Back</wizard-button
                >
              </div>
              <div class="wizard-footer-right">
                <wizard-button
                  v-if="!props.isLastStep"
                  @click.native="props.nextTab()"
                  class="wizard-footer-right"
                  :style="props.fillButtonStyle"
                  >{{ venueNextButtonText }}</wizard-button
                >

                <wizard-button
                  v-else
                  @click.native="wizardComplete"
                  class="wizard-footer-right finish-button btn-success"
                  :style="props.fillButtonStyle"
                  >{{
                    props.isLastStep ? "Add New Customer" : "Next"
                  }}</wizard-button
                >
              </div>
            </template>
          </form-wizard>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { FormWizard, TabContent, WizardButton } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import CustomerGeneralInfo from "../../Forms/Wizard/CustomerOnboardingWizard/CustomerGeneralInfo.vue";
import CustomerVenueTable from "../../Forms/Wizard/CustomerOnboardingWizard/CustomerVenuesWizard/CustomerVenueTable.vue";
import CustomerVenuesInfo from "../../Forms/Wizard/CustomerOnboardingWizard/CustomerVenuesWizard/CustomerVenuesInfo.vue";
import CustomerVenueContacts from "../../Forms/Wizard/CustomerOnboardingWizard/CustomerVenuesWizard/CustomerVenueContacts.vue";
import CustomerServicePersonnel from "../../Forms/Wizard/CustomerOnboardingWizard/CustomerVenuesWizard/CustomerServicePersonnel.vue";
import Swal from "sweetalert2";
import { initAPI } from "src/gateway/api-instance.js";

export default {
  data() {
    return {
      wizardModel: {},
      venueLoop: true,
      venueLoopFinish: false,
      venueLoopReset: false,
      venueNextButtonText: "Next",
      errorMsg: null,
      newCustomer: false,
      newCustomers: {
        customerInfo: {},
        venue: []
      },
      dummyVenue: {},
      numOfVenues: 1,
      choosenVenue: {
        servicePerson: null,
        venueContacts: null,
        venueInfo: null,
        posCredentials: null
      },
      firstVenue: true,
      backEventTriggered: false,
      wizardKey: 0
    };
  },
  computed: {
    Instance() {
      return initAPI(this.$cookies.get("Token"), process.env.VUE_APP_API_URL);
    }
  },
  components: {
    FormWizard,
    TabContent,
    WizardButton,
    CustomerGeneralInfo,
    CustomerVenueTable,
    CustomerVenuesInfo,
    CustomerVenueContacts,
    CustomerServicePersonnel
  },
  mounted() {
    //console.log("in wizard mounted")
  },
  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    wizardComplete() {
      //console.log("wizard compelete");
      this.firstVenue = true;
      this.venueLoop = true;
      this.venueLoopFinish = false;
      this.venueLoopReset = false;
      this.venueNextButtonText = "Next";
      this.choosenVenue = {
        servicePerson: null,
        venueContacts: null,
        venueInfo: null,
        posCredentials: null
      };
      this.errorMsg = null;
      this.newCustomer = true;

      // submit the data through the apis
      this.submitCustomerData();
      this.addNewCustomerInstance();

      //re-render the wizard by cganging the component key
      this.wizardKey += 1;
    },
    startVenueLoop(currentRow) {
      //console.log("loop start ");
      //console.log(currentRow)
      this.choosenVenue = currentRow;
      this.venueLoop = true;
      this.newCustomer = false;
      this.venueNextButtonText = "Next";
      this.$refs.wizard.nextTab();
    },
    resetVenueLoop() {
      //console.log(this.venueLoopReset)
      if (this.venueLoopReset == true) {
        //console.log("in reset func");
        this.venueNextButtonText = "Finish";
        this.choosenVenue = {
          servicePerson: null,
          venueContacts: null,
          venueInfo: null,
          posCredentials: null
        };
        this.venueLoop = false;
        this.firstVenue = false;
        Swal.fire({
          title: "Venue Added!",
          text: "The venue has been added succefully!",
          type: "success",
          confirmButtonClass: "btn btn-success",
          buttonsStyling: false
        });
        this.addVenueToTable();
        //console.log("nanii!", this.newCustomers.venue)
        this.dummyVenue = {};
        this.dummyVenue.customer = this.newCustomers.customerInfo.name;
        this.$refs.wizard.changeTab(4, 1);
      }
    },
    finishVenueLoop() {
      //console.log("in finish loop");
      //console.log(this.newCustomers)
      this.venueLoopReset = false;
      this.venueLoopFinish = true;
      this.$refs.wizard.navigateToTab(5);
    },
    addNewCustomerInstance() {
      //console.log("adding in the table")
      //console.log(this.newCustomers)
      this.newCustomers = {
        customerInfo: {},
        venue: []
      };
      this.numOfVenues = 0;
      //console.log(this.newCustomers)
    },
    addVenueToTable() {
      this.newCustomers.venue.push(this.dummyVenue);
      this.numOfVenues++;
    },
    preventNextStep() {
      //console.log("in prevent step")
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (
            this.venueLoop == true ||
            this.venueLoopReset == true ||
            this.venueLoopFinish
          ) {
            //console.log("huh!" + this.venueLoopReset);
            if (this.venueLoopReset == true || this.venueLoopFinish) {
              //console.log("in prevent step 2")
              this.finishVenueLoop();
            }
            resolve(true);
          } else {
            reject(
              "Please choose a venue or add a new one to proceed. Click next again to get rid of the error."
            );
          }
        }, 1000);
      });
    },
    handleErrorMessage: function(errorMsg) {
      this.errorMsg = errorMsg;
    },
    getVenueContactsData(venueContactsData) {
      this.dummyVenue.venueContacts = venueContactsData;
    },
    getServicePersonnelData(personnelData) {
      this.dummyVenue.servicePerson = personnelData;
      //console.log("returned from venue service personnel");
      //console.log(this.newCustomers);
      //console.log(this.venueLoopReset);
    },
    getVenuePOSData(POSCredentials, venueInfo, id) {
      //console.log("returned from venue info");

      this.dummyVenue.venueName = venueInfo.name;
      this.dummyVenue.venueStatus = venueInfo.venueStatus;
      this.dummyVenue.posSystem = POSCredentials.posSystem;

      this.dummyVenue.venueInfo = venueInfo;
      this.dummyVenue.venueInfo.id = id;
      this.dummyVenue.posCredentials = POSCredentials;
      //console.log(this.newCustomers);
    },
    generalInfoData(generalInfo) {
      //console.log("returned from general info");
      //console.log(this.newCustomers);
      this.dummyVenue.customer = generalInfo.name;
      this.newCustomers.customerInfo = generalInfo;
      //console.log(this.newCustomers);
    },
    submitCustomerData() {
      // el data members hna homa customerGeneralInfo, venueInfo, posCredentials,venueContactInfo and servicePersonnel
      //console.log("The submitted data")
      //console.log(this.newCustomers)
      var obj = {
        customerInfo: this.newCustomers.customerInfo,
        venues: this.newCustomers.venue
      };

      /*this.newCustomers.venue.forEach(element => {
        obj.venues.push({
          venue: element.venueInfo,
          posCredentials: element.posCredentials,
          venueContacts: element.venueContacts,
          servicePerson: element.servicePerson})
      });*/

      var myJSON = JSON.stringify(obj);
      //console.log(myJSON);

      this.Instance.post("/wizard/newCustomer", obj)
        .then(function(response) {
          //console.log(response);
        })
        .catch(function(error) {
          //console.log(error);
        });
    }
  }
};
</script>
<style lang="scss">
.vue-form-wizard .wizard-icon-circle.tab_shape {
  background-color: #9a9a9a !important;
  color: white;
}
.vue-form-wizard .wizard-tab-content {
  display: flex; // to avoid horizontal scroll when animating
  .wizard-tab-container {
    display: block;
    animation: fadeIn 0.5s;
  }
}
span.error {
  color: #e74c3c;
  font-size: 20px;
  display: flex;
  justify-content: right;
}
</style>
